<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/guanyu/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <el-row :gutter="80">
                <el-col :span="12" :xs="24">
                    <div>
                        <div style="width:20px;width: 54px;height: 4px;background: #0052D9;"></div>
                        <div class="dtitle">{{$t('name')}}</div>
                        <div class="content">{{$t('jieshao')}}</div>
                    </div>
                </el-col>
                <el-col :span="12" :xs="24">
                    <el-image style="width:100%" :src="require('@/assets/guanyu/jieshao1.png')"></el-image>
                </el-col>
            </el-row>
            <el-row style="margin-top:3%">
                <el-col :span="24" :xs="24">
                    <el-row>
                        <el-col :span="12" :xs="24">
                            <div>
                                <el-image style="width:100%" :src="require('@/assets/guanyu/jieshao2.png')"></el-image>
                            </div>
                        </el-col>
                        <el-col :span="12" :xs="0">
                            <div style="margin-top:5%">
                                <div style="margin-left:5%;font-size: 60px;font-family: SourceHanSansCN-Bold;font-weight: bold;color: rgba(0, 82, 217, 0.2);">
                                    ENTERPRISE CULTURE
                                </div>
                                <div style="height:270px;position:absolute;bottom:-12%;left:40%;width:60%;background: #41649D;">
                                    <div class="qyyj">
                                        <div class="qyyjitem">
                                            <div class="qyyjtitle">{{$t('guanyu.corporateMission')}}</div>
                                            <div class="qyyjcontent">{{$t('guanyu.control')}}</div>
                                        </div>
                                        <div class="qyyjitem">
                                            <div class="qyyjtitle">{{$t('guanyu.spirit')}}</div>
                                            <div class="qyyjcontent">{{$t('guanyu.spiritconetnt')}}</div>
                                        </div>
                                        <div class="qyyjitem">
                                            <div class="qyyjtitle">{{$t('guanyu.vision')}}</div>
                                            <div class="qyyjcontent">{{$t('guanyu.visionconetnt')}}</div>
                                        </div>
                                        <div class="qyyjitem">
                                            <div class="qyyjtitle" style="letter-spacing: 1px;">{{$t('guanyu.value')}}</div>
                                            <div class="qyyjcontent">{{$t('guanyu.valueconetnt')}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row style="margin-top:8%;">
                <el-col :span="4" :xs="12" v-for="(item,index) in list" :key="index">
                    <div>
                        <div class="listimage">
                            <el-image style="width:100%" :src="item.icon"></el-image>
                        </div>
                        <div class="listvalue">{{item.value}}</div>
                        <div class="listvalue">{{item.value2}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="licheng">
            <el-image style="width:100%" :src="require('@/assets/guanyu/licheng.jpg')"></el-image>
            <div class="licontent">
                <div class="lctitle">{{$t('guanyu.developmentHistory')}}</div>
                <div class="liyear">{{yindex.substr(1)}}</div>
                <div class="block" style="">
                    <el-timeline >
                        <div v-for="(item,indexs) in dashiji" :key="indexs">
                            <div v-if="indexs == yindex">
                                <el-timeline-item
                                    v-for="(items, index) in item"
                                    :key="index"
                                    icon="el-icon-more"
                                    type="primary"
                                    color="#ffffff"
                                    size="large">
                                {{items.events}}
                                </el-timeline-item>
                            </div>
                        </div>
                    </el-timeline>
                </div>
                <div class="lcbottom">
                    <div class="lcitem" v-for="(item,index) in dashiji" :key="index" @click="yearact(index.substr(1))" :class="index == yindex ? 'active' : ''">{{index.substr(1)}}</div>
                </div>
                
            </div>
            <div class="tubiao">
                <div class="dsjleft">
                    <i class='el-icon-arrow-left' @click="tiaodjs('left')"></i>
                </div>
                <div class="dsjright">
                    <i class='el-icon-arrow-right' @click="tiaodjs('right')"></i>
                </div>
            </div>
        </div>
        <div class="appmain">
            <div class="huanbao" >
                <el-image style="width:100%" :src="require('@/assets/guanyu/huanbao.jpg')"></el-image>
                <div class="hbcontent">
                    <div class="hbtitle">{{$t('guanyu.statement')}}</div>
                    <div class="hbvalue">{{$t('guanyu.statementcontent')}}</div>
                </div>
            </div>
            <div class="baozheng">
                <el-image style="width:100%" :src="require('@/assets/guanyu/baozheng.jpg')"></el-image>
                <div class="bzcontent">
                    <el-row>
                        <el-col :span="6" :xs="0" v-for="(item,index) in bzlist" :key="index">
                            <div class="bzitem">
                                <div class="bztitle">{{item.title}}</div>
                                <div class="bzvalue">
                                    {{item.value}}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    
                </div>
            </div>
            <div class="xiehui" v-if="$i18n.locale == 'zh'">
                <div class="xhtitle">行业协会</div>
                <el-row :gutter="30">
                    <el-col :span="8" :xs="12">
                        <div class="xhbck" @click="navurl('https://www.eesaenergy.com/')">
                            <div class="hyimage">
                                <el-image :src="require('@/assets/guanyu/hangye1.png')"></el-image>
                            </div>
                            <div class="xhbcktitle">
                                储能领跑者联盟
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" :xs="12">
                        <div class="xhbck" @click="navurl('http://www.cnesa.org/')">
                            <div class="hyimage">
                                <el-image :src="require('@/assets/guanyu/hangye2.png')"></el-image>
                            </div>
                            <div class="xhbcktitle">
                                中关村储能产业技术联盟
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" :xs="12">
                        <div class="xhbck" @click="navurl('http://jsesa.com.cn/')">
                            <div class="hyimage">
                                <el-image :src="require('@/assets/guanyu/hangye3.png')"></el-image>
                            </div>
                            <div class="xhbcktitle">
                                江苏省储能行业协会
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" :xs="12">
                        <div class="xhbck" @click="navurl('http://www.ciceia.org.cn/')">
                            <div class="hyimage">
                                <el-image :src="require('@/assets/guanyu/hangye4.png')"></el-image>
                            </div>
                            <div class="xhbcktitle">
                                中国内燃机工业协会应急动力装备专委会
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" :xs="12">
                        <div class="xhbck" @click="navurl('http://m.ceeiagjb.com/')">
                            <div class="hyimage">
                                <el-image :src="require('@/assets/guanyu/hangye5.png')"></el-image>
                            </div>
                            <div class="xhbcktitle">
                                中国电器工业协会内燃发电设备分会
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8" :xs="12">
                        <div class="xhbck" @click="navurl('http://m.ceeiagjb.com/')">
                            <div class="hyimage">
                                <el-image :src="require('@/assets/guanyu/hangye5.png')"></el-image>
                            </div>
                            <div class="xhbcktitle">
                                中国电器工业协会通用低压电器分会
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <!-- <div style="width:100%;position:relative;" v-if="$i18n.locale == 'zh' || $i18n.locale == 'hk'">
                <baidu-map class="bm-view" center="郑州众智科技" :zoom="13" :scroll-wheel-zoom='true' ak="QVv3wMHEgXh9nGuZCfyjGqRhahwWsaGI">
                    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                    <bm-marker :icon="{url: require('@/assets/lianxi/jinsuolu.png'), size: {width: 70, height: 92}}" title="众智科技股份有限公司(金梭路厂区)"  :position="{lng: 113.570653, lat: 34.806515}"  @mouseover="openinfo(1)" @mouseout="guanbiinfo()"  :dragging="false" >
                        <bm-label content="" :offset="{width:-100,height:30}" :labelStyle="{border:'none'}"></bm-label>
                    </bm-marker>
                    <bm-overlay
                      pane="labelPane"
                      :class="{sample: true, active}"
                      @draw="draw"
                      @mouseover.native="active = true"
                      @mouseleave.native="active = false">
                      <div>
                          <div class="info" v-if="key == 1">
                              <div class="image">
                                  <el-image style="width:100%" v-if="key == 1" :src="require('@/assets/guanyu/jinsuolu.png')"></el-image>
                                  <el-image style="width:100%" v-if="key == 2" :src="require('@/assets/guanyu/tianjianhu.png')"></el-image>
                              </div>
                              <div>
                                  <div class="infotitle" style="font-size: 20px;font-weight: bold;margin-top:20px">{{title}}</div>
                                  <div class="infotitle" >{{$t('theFreeCall')}}：400-0318-908</div>
                                  <div class="infotitle" >{{$t('guanyu.theForeignTradeInAStraightLine')}}：0086-371-67981000</div>
                                  <div class="infotitle" >{{$t('guanyu.phone')}}：0086-371-67988888/67981888</div>
                                  <div class="infotitle" style="padding-bottom:20px">{{address}}</div>
                              </div>
                          </div>
                      </div>
                  </bm-overlay>
                    <bm-marker :icon="{url: require('@/assets/lianxi/tianjianhu.png'), size: {width: 70, height: 92}}" title="众智科技股份有限公司(天健湖厂区)"  :position="{lng: 113.493302, lat: 34.83086}"  @mouseover="openinfo(2)" @mouseout="guanbiinfo()"  :dragging="false" >
                        <bm-label content="" :offset="{width:-100,height:30}" :labelStyle="{border:'none'}"></bm-label>
                    </bm-marker>
                    <bm-overlay
                      pane="labelPane"
                      :class="{sample: true, active}"
                      @draw="draw2"
                      @mouseover.native="active = true"
                      @mouseleave.native="active = false">
                      <div>
                          <div class="info" v-if="key == 2">
                              <div class="image">
                                  <el-image style="width:100%" v-if="key == 1" :src="require('@/assets/guanyu/jinsuolu.png')"></el-image>
                                  <el-image style="width:100%" v-if="key == 2" :src="require('@/assets/guanyu/tianjianhu.png')"></el-image>
                              </div>
                              <div>
                                  <div class="infotitle" style="font-size: 20px;font-weight: bold;margin-top:20px">{{title}}</div>
                                  <div class="infotitle" >{{$t('theFreeCall')}}：400-0318-908</div>
                                  <div class="infotitle" >{{$t('guanyu.theForeignTradeInAStraightLine')}}：0086-371-67981000</div>
                                  <div class="infotitle" >{{$t('guanyu.phone')}}：0086-371-67988888/67981888</div>
                                  <div class="infotitle" style="padding-bottom:20px">{{address}}</div>
                              </div>
                          </div>
                      </div>
                  </bm-overlay>
                </baidu-map>
            </div> -->
            <!-- <div id="tengxunmap" v-if="$i18n.locale == 'zh' || $i18n.locale == 'hk'" style="width: 100%;margin-top: 50px;"></div> -->
            <!-- <div v-if="$i18n.locale == 'en' || $i18n.locale == 'pt'">
                <div id="map" style="width: 100%;;height:600px"></div>
            </div> -->
        </div>
    </div>
</template>
<script>
import TMap from "@/utils/initMap";
import { getHistory} from "@/api/api"; 
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {BmControl,BmMarker,BmLabel,BmOverlay,BmNavigation} from 'vue-baidu-map'
export default {
    components:{
        BaiduMap,
        BmControl,
        BmMarker,
        BmLabel,
        BmOverlay,
        BmNavigation
    },
    data(){
        return{
            list:[
                {
                    title:this.$t('guanyu.controlFieldy'),
                    value:this.$t('guanyu.controlField'),
                    value2:this.$t('guanyu.controlField2'),
                    icon:require('@/assets/guanyu/icon_23years'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')
                },
                {
                    title:this.$t('guanyu.agenty'),
                    value:this.$t('guanyu.agent'),
                    value2:this.$t('guanyu.agent2'),
                    icon:require('@/assets/guanyu/icon_agent'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')
                },
                {
                    title:this.$t('guanyu.patenty'),
                    value:this.$t('guanyu.patent'),
                    value2:this.$t('guanyu.patent2'),
                    icon:require('@/assets/guanyu/icon_patent'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')
                },
                {
                    title:this.$t('guanyu.usery'),
                    value:this.$t('guanyu.user'),
                    value2:this.$t('guanyu.user2'),
                    icon:require('@/assets/guanyu/icon_employees'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')
                },
                {
                    title:this.$t('guanyu.annualOutputy'),
                    value:this.$t('guanyu.annualOutput'),
                    value2:this.$t('guanyu.annualOutput2'),
                    icon:require('@/assets/guanyu/icon_industrial'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')
                },
                {
                    title:this.$t('guanyu.informatizationy'),
                    value:this.$t('guanyu.informatization'),
                    value2:this.$t('guanyu.informatization2'),
                    icon:require('@/assets/guanyu/icon_production'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.png')
                }
            ],
            bzlist:[
                {
                    title:this.$t('guanyu.researchAndDevelopment'),
                    value:this.$t('guanyu.researchAndDevelopment2'),
                },
                {
                    title:this.$t('guanyu.smart'),
                    value:this.$t('guanyu.smart2'),
                },
                {
                    title:this.$t('guanyu.quality'),
                    value:this.$t('guanyu.quality2'),
                },
                {
                    title:this.$t('guanyu.market'),
                    value:this.$t('guanyu.market2'),
                }
            ],
            index:0,
            yindex:'y2023',
            dashiji:{},
            dashijiyearList:[],
            active: false,
            title:this.$t('guanyu.laochang'),
            address:this.$t('guanyu.laochangaddress'),
            key:0,
            showinfo:false,
            titles:''
        }
    },
    metaInfo () {
        return {
        title: this.titles,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.titles = this.$t('layout.about')+'-'+this.$t('seo.title')
        this.getList()
    },
    mounted(){
        //this.init() 
        if(this.$i18n.locale == 'zh' || this.$i18n.locale == 'hk'){
            this.txinit()
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    methods:{
        init() { 
            var myCenter=new google.maps.LatLng(34.800931566274116, 113.56453645559719);
            var mapProp = {
                center:myCenter,
                zoom:12,
                mapTypeId:google.maps.MapTypeId.ROADMAP
            };

            var map=new google.maps.Map(document.getElementById("map"),mapProp);

            var marker=new google.maps.Marker({
                position:myCenter,
                icon:require('@/assets/lianxi/jinsuolu.png')
            });
            var myCenter2=new google.maps.LatLng(34.82445938630299, 113.4872592822943);
            var marker2 = new google.maps.Marker({
                position:myCenter2,
                icon:require('@/assets/lianxi/tianjianhu.png')
            });
            marker.setMap(map);
            marker2.setMap(map);
            google.maps.event.addListener(marker,'mouseover',()=> {
                var infowindow = new google.maps.InfoWindow({
                    content:this.$t('guanyu.laochang')+'</br>'+this.$t('guanyu.laochangaddress')
                });
                infowindow.open(map,marker);
            });
            google.maps.event.addListener(marker2,'mouseover',()=> {
                var infowindow = new google.maps.InfoWindow({
                    content:this.$t('guanyu.xinchang')+'</br>'+this.$t('guanyu.xinchangaddress')
                });
                infowindow.open(map,marker2);
            });
        },
        txinit(){
            TMap.init().then((TMap) => {
                var center = new TMap.LatLng(34.800931566274116, 113.56453645559719);
                //初始化地图
                var map = new TMap.Map("tengxunmap", {
                zoom: 12, //设置地图缩放级别
                center: center, //设置地图中心点坐标
                });
                var marker = new TMap.MultiMarker({
                map: map,
                styles: {
                    // 点标记样式
                    jinsuolu: new TMap.MarkerStyle({
                    width: 70, // 样式宽
                    height: 90, // 样式高
                    src: require('@/assets/lianxi/jinsuolu.png'),
                    }),
                    tianjianhu: new TMap.MarkerStyle({
                    width: 70, // 样式宽
                    height: 90, // 样式高
                    src: require('@/assets/lianxi/tianjianhu.png'),
                    }),
                },
                geometries: [
                    {
                    id:'jinsuolu',
                    styleId: 'jinsuolu',
                    position: center,
                    },
                    {
                    id:'tianjianhu',
                    styleId: 'tianjianhu',
                    position: new TMap.LatLng(34.82445938630299, 113.4872592822943),
                    },
                ],
                });
                var infoWindow = new TMap.InfoWindow({
                        map: map,
                        position: center,
                        offset: { x: 200, y: 100 } //设置信息窗相对position偏移像素
                    });
                    infoWindow.close();//初始关闭信息窗关闭
                    //监听标注点击事件
                    marker.on("mouseover",  (evt)=> {
                        //设置infoWindow
                        infoWindow.open(); //打开信息窗
                        infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                        let neirong = ''
                        let title =''
                        let address = ''
                        if(evt.geometry.id == 'jinsuolu'){
                        title = this.$t('guanyu.laochang')
                        address = this.$t('guanyu.laochangaddress')
                        neirong = "<image style='width:240px;'  src='"+require('@/assets/guanyu/jinsuolu.png')+"'></image><div><div class='infotitle' style='font-size: 20px;font-weight: bold;margin-top:20px'>"+title+"</div>"
                        }else{
                        title = this.$t('guanyu.xinchang')
                        address = this.$t('guanyu.xinchangaddress')
                        neirong = "<image style='width:240px;'  src='"+require('@/assets/guanyu/tianjianhu.png')+"'></image><div><div class='infotitle' style='font-size: 20px;font-weight: bold;margin-top:20px'>"+title+"</div>"
                        }
                        neirong = neirong+"<div class='infotitle' >"+this.$t('theFreeCall')+"：400-0318-908</div><div class='infotitle' >"+this.$t('guanyu.theForeignTradeInAStraightLine')+"：0086-371-67981000</div><div class='infotitle' >"+this.$t('guanyu.phone')+"：0086-371-67988888/67981888</div><div class='infotitle' style='padding-bottom:20px'>"+address+"</div></div>"
                        console.log(evt.geometry.id);
                        infoWindow.setContent(neirong);//设置信息窗内容
                })
            });
        },
        openinfo(key){
            if(key == 1){
                this.title = this.$t('guanyu.laochang')
                this.address = this.$t('guanyu.laochangaddress')
            }else if(key == 2){
                this.title = this.$t('guanyu.xinchang')
                this.address = this.$t('guanyu.xinchangaddress')
            }
            this.key = key
            this.showinfo = true
        },
        guanbiinfo(){
            this.key = 0
            this.showinfo = false
        },
        leftyear(){
            let a = this.dashijiyearList.indexOf(this.yindex)
            if(a >= 0){
                let y = this.dashijiyearList[a-1]
                if(y == undefined){
                    return ''
                }else{
                    return y.substr(1)
                }
            }else{
                return ' '
            }
        },
        tiaodjs(type){
            if(type == 'left'){
                let a = this.dashijiyearList.indexOf(this.yindex)
                if(a >= 0){
                    let y = this.dashijiyearList[a-1]
                    if(y == undefined){
                        return
                    }else{
                        this.yindex = 'y'+y.substr(1)
                    }
                }else{
                    return
                }
            }else if(type == 'right') {
                let a = this.dashijiyearList.indexOf(this.yindex)
                if(a >= 0){
                    let y = this.dashijiyearList[a+1]
                    if(y == undefined){
                        return
                    }else{
                        this.yindex = 'y'+y.substr(1)
                    }
                }else{
                    return
                }
            }
        },
        rightyear(){
            let a = this.dashijiyearList.indexOf(this.yindex)
            if(a >= 0){
                let y = this.dashijiyearList[a+1]
                if(y == undefined){
                    return ''
                }else{
                    return y.substr(1)
                }
            }else{
                return ' '
            }
        },
        async getList(){
           const{data:res} = await getHistory()
           let arr = []
           for ( let i in res){
               arr.push(i)
           }
           this.dashijiyearList = arr
           this.dashiji = res
        },
        yearact(year){
            let key = 'y'+year
            this.yindex = key
        },
        draw ({el, BMap, map}) {
            const pixel = map.pointToOverlayPixel(new BMap.Point(113.570653, 34.806515))
            el.style.left = pixel.x+60 + 'px'
            el.style.top = pixel.y - 210 + 'px'
        },
        draw2 ({el, BMap, map}) {
            const pixel = map.pointToOverlayPixel(new BMap.Point(113.49371 , 34.833641))
            el.style.left = pixel.x+60 + 'px'
            el.style.top = pixel.y - 180 + 'px'
        },
        navurl(url){
            window.open(url)
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 80%;
        margin-top: 2%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
        .content{
            padding: 10px;
        }
        .dtitle{
            padding-left: 10px;
        }
        .licheng{
            display: none;
        }
        .huanbao{
            display: none;
        }
        .baozheng{
            display: none;
        }
    }
    @media screen and (max-width: 1280px) {
        .appmain{
            .dtitle{
                margin-top: 3%;
                font-size: 24px;
                font-family: SourceHanSansCN-Bold;
                font-weight: bold;
                color: #333333;
            }
            .content{
                margin-top: 2%;
                font-size: 13px;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                line-height: 30px;
                color: #333333;
                letter-spacing:1px;
                text-align:justify;
            }
        }
        .block{
            height:30vh !important; 
        }
    }
    @media screen and (max-width: 1540px) {
        .appmain{
            .dtitle{
                margin-top: 3%;
                font-size: 18px;
                font-family: Arial;
                font-weight: bold;
                color: #333333;
            }
            .content{
                font-size: 12px;
                font-family: Arial;
            }
            .qyyj{
                padding-top: 30px;
                padding-left: 6%;
                .qyyjtitle{
                    font-size: 16px;
                }
            }
            .huanbao{
                margin-top: 2%;
                .hbcontent{
                    .hbtitle{
                        font-size: 24px;
                        margin-top: 50px;
                    }
                    .hbvalue{
                        font-size: 18px;
                        margin-top: 2%;
                    }
                }
            }
            .baozheng{
                .bzcontent{
                    .bzitem{
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 13%;
                        .bztitle{
                            font-size: 20px;
                        }
                        .bzvalue{
                            font-size: 14px;
                        }
                    }
                    
                }
            }
        }
        .block{
            height:30vh !important; 
        }
    }
    .dtitle{
        margin-top: 6%;
        font-size: 24px;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #333333;
    }
    .content{
        margin-top: 3%;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        letter-spacing:1px;
        text-align:justify;
    }
    .yw{
        font-size: 40px;
        color: rgba(0, 82, 217, 0.2);
        margin-top: 6%;
        font-size: 3em;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
    }
    .qyyj{
        padding-top: 50px;
        padding-left: 8%;
        color: #FFFFFF;
        .qyyjitem{
            display: flex;
            margin-bottom: 20px;
        }
        .qyyjtitle{
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            letter-spacing: 6px;
        }
        .qyyjcontent{
            margin-left: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .licheng{
        margin-top: 5%;
        position: relative;
        .tubiao{
            display: none;
        }
        .dsjleft{
            position: absolute;
            left: 20px;
            top: 33%;
            font-size: 48px;
            font-family: OPPOSans-M;
            font-weight: normal;
            color: #FFFFFF;
            cursor: pointer;
        }
        .dsjright{
            position: absolute;
            right: 20px;
            top: 33%;
            font-size: 48px;
            font-family: OPPOSans-M;
            font-weight: normal;
            color: #FFFFFF;
            cursor: pointer;
        }
        .licontent{
            position: absolute;
            top: 10px;
            width: 100%;
            .lctitle{
                font-size: 24px;
                font-weight: bold;
                text-align: center;
                color: #FFFFFF;
                padding-top: 30px;
            }
            .liyear{
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                margin-top: 2%;
                color: #FFFFFF;
            }
            .block{
                margin-left: 42%;
                margin-top: 3%;
                height:38vh; 
                overflow: hidden;
                overflow-y: auto;
                overflow-x: hidden;
            }
            .lcbottom{
                width: 70%;
                display: flex;
                margin: 0 auto;
                color: #FFFFFF;
                margin-top: 2%;
                justify-content: space-between;
                cursor: pointer;
                font-size: 18px;
                font-family: OPPOSans-M, OPPOSans;
                font-weight: normal;
                color: #FFFFFF;
            }
            .lcitem{
                line-height: 40px;
                font-family: OPPOSans-M;
                font-weight: normal;
                color: #FFFFFF;
                font-size: 18px;
            }
            .active{
                border-bottom: 1px solid  #FFFFFF;
            }
            .block::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .licheng:hover .tubiao{
        display: block;
    }
    .listimage{
        width: 100px;
        margin: 0 auto;
    }
    .listtitle{
        text-align: center;
        margin-top: 10px;
        font-size: 30px;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #333333;
    }
    .listvalue{
        text-align: center;
        font-size: 14.5px;
        color: #666666;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
    }
    .bm-view{
        width: 100%;
        height: 600px;
        margin-top: 50px;
    }
    .info{
        width: 300px;
        background: #fff;
        text-align: center;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
        .infotitle{
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 15px;
        }
        .infoitem{
            margin-bottom: 15px;
        }
    }
    .huanbao{
        margin-top: 2%;
        position: relative;
        .hbcontent{
            top: 10px;
            width: 100%;
            position:absolute;
            .hbtitle{
                font-size: 24px;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                margin-top: 70px;
            }
            .hbvalue{
                font-size: 18px;
                color: #FFFFFF;
                text-align: center;
                margin-top: 2%;
            }
        }
        
    }
    .baozheng{
        margin-top: 2%;
        position: relative;
        .bzcontent{
            top: 0px;
            width: 100%;
            height: 100%;
            position:absolute;
            .bzitem{
                height: 460px;
                padding-left: 50px;
                padding-right: 50px;
                border-right: 1px solid rgba(255, 255, 255, 0.25);
                padding-top: 35%;
                .bztitle{
                    font-size: 24px;
                    font-family: SourceHanSansCN-Bold;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .bzvalue{
                    margin-top: 5%;
                    line-height: 30px;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
            
        }
    }
    .sample {
        width: 720px;
        overflow: hidden;
        position: absolute;
    }
    .sample.active {

    }
    .xiehui{
        margin-top: 20px;
        .xhtitle{
            text-align: center;
            font-size: 26px;
            font-weight: bold;
        }
        .xhbck{
            margin-top: 20px;
            border: 2px solid #EDEDED;
            height: 200px;
            cursor: pointer;
            .hyimage{
                width: 340px;
                margin: 0 auto;
                margin-top: 10px;
            }
            .xhbcktitle{
                text-align: center;
                font-size: 18px;
                color: #666666;
                margin-top: 5px;
            }
        }
    }
</style>
<style scoped>
 /deep/ .el-timeline-item__content{
     color: #fff;
 }
 /deep/ .el-timeline-item__timestamp{
     color: #fff;
 }
 /deep/ .el-timeline{
     font-size:16px;
 }
</style>